import React, { useState, useEffect } from "react";
import { auth } from "../../firebase";
import {
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import "react-phone-input-2/lib/style.css";
import { db } from "../../firebase";
import {
  collection,
  serverTimestamp,
  doc,
  setDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Verification from "./Verification";
import { v4 as uuidv4 } from "uuid";
import { CircularProgress } from "@mui/material";

const Register = ({ setNewUser }) => {
  const [formvalues, setFormvalues] = useState({
    name: "",
    email: "",
    password: "",
    confirmpassword: "",
    dob: "",
    gender: "",
    phone: "",
    referralcode: "",
  });
  const [nameerror, setNameError] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [passworderror, setPasswordError] = useState("");
  const [confirmpassworderror, setConfirmpasswordError] = useState("");
  const [phoneerror, setPhoneError] = useState("");
  const [gendererror, setGenderError] = useState("");
  const [doberror, setDobError] = useState("");
  const [referralerror, setReferralError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [referredNumber, setReferredNumber] = useState("");
  const [loading, setLoading] = useState(false);
  // for phone number verification
  const [isVerificationRequired, setIsVerificationRequired] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // to set min and max in input type='date'
  const today = new Date();
  const minDate = new Date(today.getFullYear() - 100, 0, 1);
  const maxDate = new Date(today.getFullYear() - 18, 11, 31);

  //Function for handleChange for input field
  const handleChange = (event) => {
    const { name, value } = event.target;
    let formattedValue = value;

    //if its phone
    if (name === "phone") {
      // remove all non-numeric characters from the input
      formattedValue = value.replace(/\D/g, "");

      // add hyphen after third and sixth digits
      if (formattedValue.length > 3 && formattedValue.length < 7) {
        formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(
          3
        )}`;
      } else if (formattedValue.length >= 7) {
        formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(
          3,
          6
        )}-${formattedValue.slice(6, 10)}`;
      }
    }

    setFormvalues((prevState) => ({
      ...prevState,
      [name]: formattedValue,
    }));
  };

  // validation for fullname
  const validateName = () => {
    let error = "";

    if (!formvalues.name.trim()) {
      error = "Full Name is required";
    } else if (formvalues.name.trim().length < 4) {
      error = "Full name must be atleast 4 characters";
    } else if (formvalues.name.trim().length > 30) {
      error = "Full name must not exceed 30 characters";
    }

    setNameError(error);
  };

  // validation for Email address
  const validateEmail = (err) => {
    let error = "";
    const regex = /\S+@\S+\.\S+/;
    if (!formvalues.email.trim()) {
      error = "Email is required";
    } else if (!regex.test(formvalues.email)) {
      error = "Please enter valid email address";
    }
    if (err === "auth/email-already-in-use") {
      error = "Email already exists";
    }
    setEmailError(error);
  };

  // validation for password
  const validatePassword = () => {
    let error = "";

    if (!formvalues.password.trim()) {
      error = "Password is required";
    } else if (formvalues.password.trim().length < 8) {
      error = "Password must be atleast 8 characters";
    }

    // else if (
    //   !/[a-z]/.test(formvalues.password) ||
    //   !/[A-Z]/.test(formvalues.password) ||
    //   !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(formvalues.password)
    // ) {
    //   error =
    //     "Password must contain at least one lowercase letter, one uppercase letter, and one special character";
    // }

    setPasswordError(error);
  };

  // validation for confirmpassword
  const validateConfirmpassword = () => {
    let error = "";
    if (!formvalues.confirmpassword.trim()) {
      error = "Confirm password is required";
    } else if (
      formvalues.password.trim() !== formvalues.confirmpassword.trim()
    ) {
      error = "Passwords do not match";
    }
    setConfirmpasswordError(error);
  };

  // validation for phone number format
  const validatePhonenumber = () => {
    let error = "";
    const pattern = /^(\([0-9]{3}\)\s*|[0-9]{3}-)\s*[0-9]{3}-\s*[0-9]{4}$/;
    const isValid = pattern.test(formvalues.phone);
    if (!formvalues.phone.trim()) {
      error = "Phone number is required";
    } else if (!isValid) {
      error = "Please enter a valid US phone number";
    }
    setPhoneError(error);
  };

  //validation for gender
  const validateGender = () => {
    let error = "";
    if (!formvalues.gender.trim()) {
      error = "Gender is required";
    }
    setGenderError(error);
  };

  // validation for DOB
  const validateDateofbirth = () => {
    let error = "";
    const today = new Date();
    const minDate = new Date(today.getFullYear() - 100, 0, 1);
    const maxDate = new Date(today.getFullYear() - 18, 11, 31);
    const selectedDate = new Date(formvalues.dob);

    if (!formvalues.dob.trim()) {
      error = "Date of Birth is required";
    } else if (selectedDate < minDate) {
      error =
        "Date of Birth cannot be less than " +
        minDate.toISOString().split("T")[0];
    } else if (selectedDate > maxDate) {
      error = "You must be at least 18 years old";
    }
    setDobError(error);
  };

  // Function to check all the validations
  const checkAllValidations = () => {
    let hasErrors = false;

    validateName();
    validateEmail();
    validateConfirmpassword();
    validateDateofbirth();
    validateGender();
    validatePassword();
    validatePhonenumber();

    // check if there are any errors
    if (
      nameerror ||
      emailerror ||
      passworderror ||
      confirmpassworderror ||
      gendererror ||
      phoneerror ||
      doberror
    ) {
      hasErrors = true;
    }

    // check if no input data was entered
    if (
      !formvalues.name.trim() ||
      !formvalues.email.trim() ||
      !formvalues.password.trim() ||
      !formvalues.confirmpassword.trim() ||
      !formvalues.gender.trim() ||
      !formvalues.phone.trim() ||
      !formvalues.dob.trim()
    ) {
      hasErrors = true;
    }

    // display success or failure message
    if (!hasErrors) {
      verifyEmailExists();
    }
  };
  const verifyEmailExists = async () => {
    try {
      const result = await fetchSignInMethodsForEmail(auth, formvalues.email);
      if (result.length > 0) {
        setEmailError("Email already exists");
        toast.error("Email already exists", {
          position: "top-right",
          theme: "light",
          toastId: "emailexist",
        });
      } else {
        verifyPhoneNumber();
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
      // Handle error appropriately
    }
  };

  // to verify the captcha
  const onCaptchaVerify = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            verifyPhoneNumber();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  };

  // verify the phone number with firebase
  const verifyPhoneNumber = async () => {
    onCaptchaVerify();
    const appVerifier = window.recaptchaVerifier;

    const phone = "+1" + formvalues.phone.replace(/[^0-9]/g, "");

    await signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(true);
        setIsVerificationRequired(true);
      })
      .catch((error) => {
        if (error.code === "auth/too-many-requests") {
          toast.error("Error while verifying, Please refresh the page", {
            position: "top-right",
            theme: "light",
            toastId: "toomanyrequest",
          });
        }
        if (error.code === "auth/invalid-phone-number") {
          setPhoneError("Please enter valid Phone number");
        }
      });
  };

  // once email and phone is verified then add the user data to database
  const sendUserData = async () => {
    let currentDate = new Date();
    // get age
    let age = currentDate.getFullYear() - formvalues.dob.slice(0, 4);

    //create document for user

    // create email and password in authentication (firebase)
    await createUserWithEmailAndPassword(
      auth,
      formvalues.email,
      formvalues.password
    )
      .then(async () => {
        //create user document
        const userinfo = doc(collection(db, "users/userProfiles/AllUsers"));

        const username = formvalues.name.split(" ")[0];
        const id = uuidv4().slice(14, 18);

        const userDocID = username + id;

        setDoc(doc(db, "users/userProfiles/AllUsers", userDocID), {
          name: formvalues.name,
          email: formvalues.email,
          phone: formvalues.phone,
          gender: formvalues.gender,
          dob: formvalues.dob,
          age: age,
          createdAt: serverTimestamp(),
          referralCode: formvalues.referralcode,
          referredNumber: 0,
          address: "",
          city: "",
          zipcode: "",
          state: "",
          maritialstatus: "",
          imageUrl: "",
          healthForm: false,
          // userID: userinfo.id,
          userID: userDocID,
          userDoc: false,
        }).then(async () => {
          createReferrals();
          if (formvalues.referralcode.length !== 0) {
            const referralCode = formvalues.referralcode;
            const updateUserInfo = doc(
              db,
              "users/userProfiles/AllUsers",
              referralCode
            );
            await updateDoc(updateUserInfo, {
              referredNumber: referredNumber + 1,
            });
          }
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.code === "auth/email-already-in-use") {
          toast.error("Email already exists", {
            position: "top-right",
            theme: "light",
            toastId: "emailexist",
          });
        }
      });
  };

  // to create the referrals card
  const createReferrals = async () => {
    try {
      const docName = "userGiftcards";
      const documentRef = doc(db, "/users", docName);
      const documentSnap = await getDoc(documentRef);
      if (documentSnap.exists()) {
        const subCollectionRef = collection(documentRef, formvalues.email);
        const documentOne = doc(subCollectionRef);
        const documentTwo = doc(subCollectionRef);
        const documentThree = doc(subCollectionRef);
        const documentFour = doc(subCollectionRef);
        const documentFive = doc(subCollectionRef);

        const document1 = {
          id: 1,
          docID: documentOne.id,
          isActive: false,
          isExpired: false,
          endDate: "",
          referralDisclaimer: "With minimum purchase of 200$",
          referralPrice: "$50 off on Any Service",
          referralNumber: 0,
          secretCode: uuidv4().slice(0, 6),
          startDate: "",
          title: "1st Gift Card",
          toEarn: "Welcome Gift Card",
        };

        const document2 = {
          id: 2,
          docID: documentTwo.id,
          isActive: false,
          isExpired: false,
          endDate: "",
          referralDisclaimer: "With minimum purchase of 300$",
          referralPrice: "$75 off on Any Service",
          referralNumber: 3,
          secretCode: uuidv4().slice(0, 6),
          startDate: "",
          title: "2nd Gift Card",
          toEarn: "To earn after 3 Referrals",
        };
        const document3 = {
          id: 3,
          docID: documentThree.id,
          isActive: false,
          isExpired: false,
          endDate: "",
          referralDisclaimer: "With minimum purchase of 400$",
          referralPrice: "$100 off on Any Service",
          referralNumber: 8,
          secretCode: uuidv4().slice(0, 6),
          startDate: "",
          title: "3rd Gift Card",
          toEarn: "To earn after 8 Referrals",
        };

        const document4 = {
          id: 4,
          docID: documentFour.id,
          isActive: false,
          isExpired: false,
          endDate: "",
          referralDisclaimer: "With minimum purchase of 500$",
          referralPrice: "$150 off on Any Service",
          referralNumber: 15,
          secretCode: uuidv4().slice(0, 6),
          startDate: "",
          title: "4th Gift Card",
          toEarn: "To earn after 15 Referrals",
        };

        const document5 = {
          id: 5,
          docID: documentFive.id,
          isActive: false,
          isExpired: false,
          endDate: "",
          referralDisclaimer: "With minimum purchase of 750$",
          referralPrice: "$200 off on Any Service",
          referralNumber: 25,
          secretCode: uuidv4().slice(0, 6),
          startDate: "",
          title: "5th Gift Card",
          toEarn: "To earn after 25 Referrals",
        };

        await setDoc(doc(subCollectionRef, documentOne.id), document1);
        await setDoc(doc(subCollectionRef, documentTwo.id), document2);
        await setDoc(doc(subCollectionRef, documentThree.id), document3);
        await setDoc(doc(subCollectionRef, documentFour.id), document4);
        await setDoc(doc(subCollectionRef, documentFive.id), document5);
      }
      console.log("sucessfully created referral cards");
    } catch (Err) {
      console.log(Err);
    }
  };

  // Function to register
  // check if referral code is enter or not if its there then it will check the db
  const register = async (e) => {
    e.preventDefault();

    if (formvalues.referralcode.length === 0) {
      checkAllValidations();
    } else {
      try {
        const docRef = doc(
          db,
          "/users/userProfiles/AllUsers",
          formvalues.referralcode
        );

        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          const referredno = data.referredNumber;
          setReferredNumber(referredno);
          checkAllValidations();
        } else {
          setReferralError("Please enter valid Referral code");
        }
      } catch (Err) {
        console.log(Err);
      }
    }
  };

  return (
    <>
      {isVerificationRequired ? (
        <Verification
          setNewUser={setNewUser}
          setIsVerificationRequired={setIsVerificationRequired}
          sendUserData={sendUserData}
        />
      ) : (
        <div className="flex flex-col text-gray-700 w-full justify-center md:pt-12  md:overflow-auto md:hover:overflow-y-scroll">
          <div className="flex justify-center items-center p-2 mt-4">
            <img className="h-24" src="../assets/logo.gif" alt="Bitcare" />
          </div>
          <div className="flex flex-col gap-4 p-4">
            <div className="grid gap-2 ">
              <span className="text-3xl font-bold">Create new account</span>
              <span className="text-sm font-medium">
                <span>Already have an account? </span>
                <span
                  onClick={() => setNewUser(false)}
                  className="cursor-pointer text-primary-color font-semibold hover:underline"
                >
                  Log in
                </span>
              </span>
            </div>

            {/* form content */}
            <div className="mt-4">
              <form onSubmit={register} className="grid gap-2">
                <div>
                  <label className="font-bold text-xs uppercase tracking-wide">
                    Full Name
                  </label>
                  <Tooltip
                    title={nameerror}
                    open={Boolean(nameerror)}
                    placement="top-end"
                  >
                    <input
                      type="text"
                      name="name"
                      placeholder="Full Name"
                      value={formvalues.name}
                      onChange={handleChange}
                      onBlur={validateName}
                      className={`mt-1 block w-full  rounded-sm  shadow-sm  sm:text-sm
                  ${
                    nameerror
                      ? "border-red-500 focus:ring-0 focus:border-red-500"
                      : "border-gray-400 focus:border-primary-color focus:ring-primary-color focus:ring-0"
                  }
                `}
                    />
                  </Tooltip>
                </div>

                {/* email */}
                <div>
                  <label className="font-bold text-xs uppercase tracking-wide">
                    Email
                  </label>
                  <Tooltip
                    title={emailerror}
                    open={Boolean(emailerror)}
                    placement="top-end"
                  >
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formvalues.email}
                      onChange={handleChange}
                      onBlur={validateEmail}
                      className={`mt-1 block w-full  rounded-sm  shadow-sm  sm:text-sm
                  ${
                    emailerror
                      ? "border-red-500 focus:ring-0 focus:border-red-500"
                      : "border-gray-400 focus:border-primary-color focus:ring-primary-color focus:ring-0"
                  }
                `}
                    />
                  </Tooltip>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                  {/* Password */}
                  <div>
                    <label className="font-bold text-xs uppercase tracking-wide">
                      Password
                    </label>
                    <Tooltip
                      title={passworderror}
                      open={Boolean(passworderror)}
                      placement="top-end"
                    >
                      <div className="relative mt-1 rounded-sm shadow-sm">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          placeholder="********"
                          value={formvalues.password}
                          onChange={handleChange}
                          onBlur={validatePassword}
                          className={`mt-1 block w-full  rounded-sm  shadow-sm  sm:text-sm
                      ${
                        passworderror
                          ? "border-red-500 focus:ring-0 focus:border-red-500"
                          : "border-gray-400 focus:border-primary-color focus:ring-primary-color focus:ring-0"
                      }
                    `}
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 px-3 py-2 text-gray-600 hover:text-gray-800 focus:outline-none"
                          onClick={handleTogglePassword}
                        >
                          {showPassword ? (
                            <AiFillEye className="w-5 h-5" aria-hidden="true" />
                          ) : (
                            <AiFillEyeInvisible
                              className="w-5 h-5"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      </div>
                    </Tooltip>
                  </div>
                  {/* Confirm password */}
                  <div>
                    <label className="font-bold text-xs uppercase tracking-wide">
                      Confirm Password
                    </label>
                    <Tooltip
                      title={confirmpassworderror}
                      open={Boolean(confirmpassworderror)}
                      placement="top-end"
                    >
                      <div className="relative mt-1 rounded-sm shadow-sm">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          id="confirmpassword"
                          name="confirmpassword"
                          placeholder="********"
                          value={formvalues.confirmpassword}
                          onChange={handleChange}
                          onBlur={validateConfirmpassword}
                          className={`mt-1 block w-full  rounded-sm  shadow-sm  sm:text-sm
                      ${
                        confirmpassworderror
                          ? "border-red-500 focus:ring-0 focus:border-red-500"
                          : "border-gray-400 focus:border-primary-color focus:ring-primary-color focus:ring-0"
                      }
                    `}
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 px-3 py-2 text-gray-600 hover:text-gray-800 focus:outline-none"
                          onClick={handleToggleConfirmPassword}
                        >
                          {showConfirmPassword ? (
                            <AiFillEye className="w-5 h-5" aria-hidden="true" />
                          ) : (
                            <AiFillEyeInvisible
                              className="w-5 h-5"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      </div>
                    </Tooltip>
                  </div>
                </div>
                {/* Phone number */}
                <div>
                  <label className="font-bold text-xs uppercase tracking-wide">
                    Phone Number
                  </label>
                  <Tooltip
                    title={phoneerror}
                    open={Boolean(phoneerror)}
                    placement="top-end"
                  >
                    <input
                      type="tel"
                      name="phone"
                      placeholder="xxx-xxx-xxxx"
                      value={formvalues.phone}
                      onChange={handleChange}
                      onBlur={validatePhonenumber}
                      className={`mt-1 block w-full  rounded-sm  shadow-sm  sm:text-sm
                      ${
                        phoneerror
                          ? "border-red-500 focus:ring-0 focus:border-red-500"
                          : "border-gray-400 focus:border-primary-color focus:ring-primary-color focus:ring-0"
                      }
                    `}
                    />
                  </Tooltip>
                </div>
                <div>
                  <label className="font-bold text-xs uppercase tracking-wide">
                    Referral Code (Optional)
                  </label>
                  <Tooltip
                    title={referralerror}
                    open={Boolean(referralerror)}
                    placement="top-end"
                  >
                    <input
                      type="text"
                      name="referralcode"
                      placeholder="referral code"
                      value={formvalues.referralcode}
                      onChange={handleChange}
                      className={`mt-1 w-full block rounded-sm shadow-sm sm:text-sm
                      ${
                        referralerror
                          ? "border-red-500 focus:ring-0 focus:border-red-500"
                          : "border-gray-400 focus:border-primary-color focus:ring-primary-color focus:ring-0"
                      }
                     
                    `}
                    />
                  </Tooltip>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                  {/* Gender */}
                  <div>
                    <label className="font-bold text-xs uppercase tracking-wide">
                      Gender
                    </label>
                    <Tooltip
                      title={gendererror}
                      open={Boolean(gendererror)}
                      placement="top-end"
                    >
                      <select
                        name="gender"
                        value={formvalues.gender}
                        onChange={handleChange}
                        onBlur={validateGender}
                        className={`mt-1 block w-full  rounded-sm  shadow-sm  sm:text-sm
                      ${
                        gendererror
                          ? "border-red-500 focus:ring-0 focus:border-red-500"
                          : "border-gray-400 focus:border-primary-color focus:ring-primary-color focus:ring-0"
                      }
                    `}
                      >
                        <option value="">Select Gender</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>other</option>
                      </select>
                    </Tooltip>
                  </div>
                  {/* Date of birth */}
                  <div>
                    <label className="font-bold text-xs uppercase tracking-wide">
                      Date of birth
                    </label>
                    <Tooltip
                      title={doberror}
                      open={Boolean(doberror)}
                      placement="top-end"
                    >
                      <input
                        type="date"
                        name="dob"
                        value={formvalues.dob}
                        onChange={handleChange}
                        onBlur={validateDateofbirth}
                        max={maxDate.toISOString().slice(0, 10)}
                        min={minDate.toISOString().slice(0, 10)}
                        className={`mt-1 block w-full  rounded-sm  shadow-sm  sm:text-sm
                      ${
                        doberror
                          ? "border-red-500 focus:ring-0 focus:border-red-500"
                          : "border-gray-400 focus:border-primary-color focus:ring-primary-color focus:ring-0"
                      }
                    `}
                      />
                    </Tooltip>
                  </div>
                  <div id="recaptcha-container"></div>
                </div>

                <button
                  type="submit"
                  className="bg-primary-color p-3 text-white font-semibold uppercase hover:shadow-xl mt-4 outline-none
                  items-center flex gap-2 justify-center
                  "
                >
                  {loading && <CircularProgress color="inherit" size={20} />}
                  Register
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Register;

/**
 * 
This component handles user registration by validating user inputs, checking for existing email addresses, 
verifying phone numbers, and creating user profiles in the Firebase authentication and Firestore database.

**State variables:
 The component uses multiple state variables to manage form data, error messages, and loading status.

**Input Field Validation: 
The component uses various validation functions (validateName, validateEmail, validatePassword, etc.) to validate the user input for each field. If there are any validation errors, the error messages are displayed using tooltips.

**Email Verification: 
The component uses Firebase's fetchSignInMethodsForEmail function to check if the email address already exists in the authentication database. 
If the email is available, the user registration process proceeds; otherwise, an error message is displayed.

**Phone Number Verification: 
The component uses Firebase's phone number verification process (signInWithPhoneNumber) with the help of RecaptchaVerifier.
 If the phone number is valid, it sets isVerificationRequired to true, which renders the Verification component
  to handle phone number verification.

**Verification Component: 
The Verification component handles phone number verification by taking a verification code 
from the user and registering the user data after successful verification.

**Referral Code:
 If the user enters a referral code during registration, the component checks if the referral code exists 
 in the Firestore database. If valid, it updates the referredNumber value of the referred user.

**Firestore Database Operations:
 After successful email and phone verification, the user data is added to the Firestore database using setDoc.

**Referral Cards:
 The component creates three referral cards for the newly registered user using the createReferrals function 
 and stores them in the Firestore database.

**Register Function:
 The register function is triggered when the user submits the registration form. 
 It checks for all validations and performs email and phone verification if necessary.
  After verification, it proceeds with user data creation.
 */
