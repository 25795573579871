import React, { useState, useEffect } from "react";
import { Loading } from "../../components";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useStateContext } from "../../contexts/ContextProvider";

const PreandPostInstructions = () => {
  const [treatments, setTreatments] = useState([]);
  const [loading, setLoading] = useState(false);
  const { activeMenu } = useStateContext();

  // Fetch the details of the treatments intruction from Bitcare_Instructions collection
  useEffect(() => {
    const getTreatmentInstructions = async () => {
      try {
        const instructionRef = collection(db, "Bitcare_Instructions");
        await getDocs(instructionRef).then((response) => {
          setLoading(true);
          const treatments = response.docs.map((doc) => ({
            ...doc.data(),
          }));
          setTreatments(treatments);
        });
      } catch (err) {
        console.log(err);
      }
    };
    getTreatmentInstructions();
  }, []);

  return (
    <div className="mt-20 md:mt-10 mx-3">
      <div className="bg-secondary-bg p-3 md:p-6 mt-10 rounded-sm text-gray-700  md:mx-5 mb-5">
        <h2 className="font-bold text-xl text-center my-4">
          Pre and Post Treatment Instructions
        </h2>
        {loading ? (
          <div
            className={`grid xl:grid-cols-4 md:grid-cols-2 gap-4 ${
              activeMenu ? "md:grid-cols-1 lg:grid-cols-2" : "lg:grid-cols-3"
            }`}
          >
            {treatments?.map((item) => (
              <div
                key={item.id}
                className="bg-white rounded-sm shadow-sm p-4 flex flex-col gap-2"
              >
                <img
                  src={item.image}
                  alt={item.name}
                  className="h-[230px] object-cover"
                />
                <span className="font-semibold capitalize text-sm md:text-base text-center">
                  {item.name}
                </span>
                <a
                  href={item.file}
                  target="_blank"
                  rel="noreferrer"
                  className="bg-button-bg text-white text-center py-2 rounded-sm font-medium text-sm hover:cursor-pointer hover:shadow-lg"
                >
                  Instructions
                </a>
              </div>
            ))}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default PreandPostInstructions;

/**
 
**Documentation: PreandPostInstructions Component
The PreandPostInstructions component represents a page that displays pre and post-treatment instructions for various treatments.
 It fetches treatment instructions from the Firebase collection Bitcare_Instructions 
 and displays them in a grid layout, including the treatment image, name, and a link to the instructions file.

**Dependencies:
react: The core library for building user interfaces in React applications.
firebase: A backend-as-a-service platform that allows developers to build web and mobile applications without managing servers.
react-context: A React context library used to manage global application state.

**State Variables:
treatments: An array state variable that stores the treatment instructions fetched from the Firebase collection.
loading: A boolean state variable that determines whether the treatment instructions are being loaded or not.
activeMenu: Holds the current state of the active menu.

**Hooks:
useEffect: A hook that runs side effects in functional components. It is used to fetch treatment instructions from the Firebase collection Bitcare_Instructions.

**Component Structure:
1. The component fetches treatment instructions from the Firebase collection Bitcare_Instructions using the useEffect hook.
2. The fetched treatment instructions are stored in the treatments state array, and the loading state is set to true to indicate that the data has been loaded.
3. The component renders a section titled "Pre and Post Treatment Instructions" with a header and a grid layout.
4. The grid layout displays treatment cards for each treatment with an image, treatment name, and a link to the instructions file.
5. The component uses conditional rendering to display a loading indicator if the loading state is false.

**Rendered Output:
The component renders a page that displays pre and post-treatment instructions for various treatments. 
Each treatment is represented as a card with an image, treatment name, and a link to the instructions file.

 */
