import React, { useState, useEffect } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

const Advertisement = () => {
  // State to manage the current index of the slide being displayed
  const [currentIndex, setCurrentIndex] = useState(0);

  // State to store the slide data fetched from Firebase
  const [slide, setSlide] = useState([]);

  // Fetch advertisement data from Firebase on component mount
  useEffect(() => {
    const fetchadds = async () => {
      const addRef = collection(db, "Ads");
      await getDocs(addRef).then((response) => {
        const info = response.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }));
        setSlide(info);
      });
    };
    setTimeout(() => {
      fetchadds();
    }, 1000);
  }, []);

  // Function to navigate to the previous slide
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slide.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  // Function to navigate to the next slide
  const nextSlide = () => {
    const isLastSlide = currentIndex === slide.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="max-w-full h-[400px] w-full m-auto relative group bg-secondary-bg">
      {/* Display the image for the current slide */}
      <div
        style={{
          backgroundImage: `url(${slide[currentIndex]?.data?.image})`,
        }}
        className="w-full h-full rounded-sm bg-center bg-cover duration-500"
      ></div>
      {/* Previous slide button */}
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      {/* Next slide button */}
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
    </div>
  );
};

export default Advertisement;

/**Documentation

 **Overview
 The Advertisement component is responsible for displaying a slideshow of advertisements fetched from a Firebase collection named "Ads."
 It renders an image, previous and next slide buttons, and handles the logic to navigate between slides.

 ** State:
 .currentIndex: Represents the index of the current slide being displayed.
 .slide: Stores the advertisement data fetched from Firebase.

 **UseEffect:
 The useEffect hook is used to fetch advertisement data from Firebase when the component mounts.
 The data is stored in the slide state variable.
  There is a delay of 1000ms (1 second) before fetching the data to allow the component to render initially.

 **Functions:
 **`prevSlide` :  
 This function is called when the previous slide button is clicked.
 It updates the currentIndex state to navigate to the previous slide.
  If the current slide is the first slide, it will move to the last slide, creating a circular loop.

 **`nextSlide` : T
  This function is called when the next slide button is clicked.
 It updates the currentIndex state to navigate to the next slide.
  If the current slide is the last slide, it will move to the first slide, creating a circular loop.

**/
