import React from "react";
import { IoIosArrowForward } from "react-icons/io";

const VagaroLocation = () => {
  return (
    <div className="mt-20 md:mt-10 mx-3">
      <div className="md:bg-secondary-bg md:p-6 mt-4 rounded-sm md:mx-5 flex justify-center">
        <div className="w-full xl:w-[50%] grid gap-4">
          {location.map((location) => (
            <div key={location.id} className="bg-white shadow p-4 rounded-sm">
              <a
                href={location.link}
                target="_blank"
                rel="noreferrer"
                className="flex justify-between items-center rounded-sm
          bg-secondary-bg transition-all ease-in duration-300 hover:bg-button-bg
           hover:text-white  text-gray-700  p-3 cursor-pointer"
              >
                <div className="flex flex-col gap-2 flex-1">
                  <span className="text-xl font-bold tracking-wider capitalize">
                    {location.name}
                  </span>
                  <span className="text-base font-semibold md:text-base md:font-medium whitespace-pre-wrap">
                    {location.address}
                  </span>
                </div>
                <div>
                  <IoIosArrowForward size={24} />
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VagaroLocation;

const location = [
  {
    id: "location2",
    name: "Plano, TX",
    value: "plano",
    address: "2100 Dallas Pkwy Unit 115, Plano, TX 75093",
    link: "https://app.bitcare.co/BookAppointment/Plano.html",
  },
  {
    id: "location1",
    name: "Frisco, TX",
    address: "8837 Lebanon Rd, Suite 200, Frisco, TX 75034",
    value: "frisco",
    link: "https://app.bitcare.co/BookAppointment/Frisco.html",
  },
];

/**
 * 
 ** Documentation: VagaroLocation Component

The VagaroLocation component represents a page that displays information about different locations along with links 
to book appointments using the Vagaro booking service.
 It renders a list of locations with their names, addresses, and booking links. 
 When a location is clicked, the user is redirected to the respective Vagaro booking page.

*?Dependencies:
* react: The core library for building user interfaces in React applications.
* react-router-dom: A library that provides routing functionality for React applications.
* react-icons/io: A library that provides access to Icon components for various popular icon sets.


**Hooks:
* useNavigate: A hook from react-router-dom that returns a function to navigate to different routes programmatically.

*?Component Structure:
The component is a functional component that uses the useNavigate hook for navigation.
The component maps through the location array and renders a list of locations using the information from the array.

*?Locations Data:
The component uses the location array to store information about different locations. 
Each location object contains the following properties:

* id: A unique identifier for the location.
* name: The name of the location.
* address: The address of the location.
* value: A value associated with the location (e.g., "plano" or "frisco").
* link: The booking link for the location.

**Booking Link Handling:
The handleClick function is used to navigate to the respective booking page based on the value associated 
with the clicked location. If the value is "plano", it navigates to "/vagaroplano", and if the value is "frisco", 
it navigates to "/vagarofrisco".

**Rendered Output:
The component renders a list of location cards. Each card displays the location's name and address. 
The cards are clickable and use a link to open the Vagaro booking page in a new tab when clicked. 
The arrow icon (IoIosArrowForward) is displayed at the end of each card to indicate that it is clickable.
 * 
 * 
 */
