import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";
import { useStateContext } from "../../contexts/ContextProvider";
import { useDispatch } from "react-redux";
import { SetMembership } from "../../redux/reducers/selectedReducer";

const Membershipcard = () => {
  // state to store the membership card details
  const [card, setCard] = useState([]);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  // usecontext for slidebar -used for responsiveness
  const { activeMenu } = useStateContext();

  const dispatch = useDispatch();

  // Fetch membership details from firestore on component mount
  useEffect(() => {
    const fetchmembership = async () => {
      const memberRef = collection(db, "Membership");
      await getDocs(memberRef).then((response) => {
        setLoading(true);
        const info = response.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }));
        setCard(info);
      });
    };
    setTimeout(() => {
      fetchmembership();
    }, 1000);
  }, []);

  // Function to go the single membership page and store the membership name in redux
  const handleClick = (name) => {
    navigate("/singlemembership");
    dispatch(SetMembership(name));
  };

  return (
    <div className="rounded-sm">
      <div className="text-xl font-bold p-2 text-primary-color">
        Our Memberships
      </div>
      {loading ? (
        <div className="">
          {card && (
            <div
              className={`md:flex md:w-[684px] lg:w-full overflow-x-auto gap-2 
              ${activeMenu ? "md:w-[390px] lg:w-[595px] xl:w-full" : ""}
              `}
            >
              {card.map((item) => (
                <div
                  key={item.id}
                  className="bg-white mt-4 md:mt-0 w-fit rounded-sm shadow-sm flex-shrink-0 border-1 hover:shadow-md"
                >
                  <div>
                    <img
                      src={item.data.image}
                      alt="membership_image"
                      className="md:w-[200px] w-full h-[180px] object-cover"
                    />
                  </div>
                  <div className="grid gap-2 p-4">
                    <div className="text-xl font-bold">{item.data.name}</div>
                    <div className="text-md font-semibold">
                      {item.data.price}
                    </div>
                    <div
                      onClick={() => handleClick(item.data.name)}
                      className="text-sm text-primary-color font-bold hover:underline cursor-pointer"
                    >
                      See benefits
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="h-[280px] flex justify-center items-center bg-secondary-bg">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Membershipcard;

/**
 **State:
 card: Stores the membership data fetched from Firebase.
 loading: Represents the loading state while waiting for the data to be fetched.

 **Dependencies:
 React: This component utilizes React library features.
 Firebase: Firebase Firestore is used to fetch membership data.
 useNavigate: A hook from React Router DOM to handle navigation.

 **Context:
 useStateContext: Custom hook to access the application state using React Context.
 useDispatch: Hook from React Redux to dispatch actions to the Redux store.

 **UseEffect:
 The useEffect hook is used to fetch membership data from Firebase when the component mounts.
 The data is then stored in the card state variable. While fetching the data,
 the loading state is set to true to show a loading indicator until the data is ready to display.
 There is a delay of 1000ms (1 second) before fetching the data to mimic loading time.

 **Functions:
 handleClick: This function is called when a membership card is clicked.
 It navigates the user to the "/singlemembership" page using useNavigate from React Router DOM
 and dispatches the selected membership name to the Redux store using dispatch from React Redux.
 */
