import React from "react";
import { NavLink } from "react-router-dom";
import { links } from "../data/data";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../contexts/ContextProvider";

const Sidebar = () => {
  const { activeMenu, setActiveMenu, screenSize } = useStateContext();

  const handleCloseSidebar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };
  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-sm text-gray-700 text-md m-2  bg-[#E8EBEE]";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-sm text-gray-700  hover:bg-light-gray text-md m-2";

  return (
    <div
      className=" h-screen md:overflow-hidden 
      overflow-auto md:hover:overflow-auto pb-10 mb-3"
    >
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <NavLink
              to="/"
              onClick={handleCloseSidebar}
              className="items-center gap-2 p-2 flex w-full"
            >
              <img
                src="./assets/logo.gif"
                alt="bitcarelogo"
                className="w-full h-20 object-contain"
              />
            </NavLink>
            <button
              type="button"
              className="
                text-xl rounded-full p-3 text-slate-600
                hover:bg-light-gray mt-4 block md:hidden
                "
              onClick={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
            >
              <MdOutlineCancel />
            </button>
          </div>
          {/* item */}
          <div className="mt-10">
            {links.map((item) => (
              <div key={item.id}>
                <p className="text-primary-color font-semibold m-3 mt-4 uppercase">
                  {item.title}
                </p>
                {item.links.map((menu) => (
                  <NavLink
                    // to={`/${menu.link}`}
                    to={menu.link}
                    key={menu.name}
                    onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    {menu.icon}
                    <span>{menu.name}</span>
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;

/**
 * The Sidebar component is a navigation sidebar that displays a list of links and icons for different sections of the application. It also includes a close button that allows users to hide the sidebar on smaller screens.

**Component Structure:

1. The component imports necessary dependencies from React, React Router DOM, and the custom useStateContext hook.
2.The Sidebar function component is defined.
3.It retrieves the activeMenu and screenSize states from the useStateContext hook.
4.The handleCloseSidebar function is defined to close the sidebar when the close button is clicked on smaller screens.
5.The component defines two CSS classes activeLink and normalLink to style the active and normal links in the sidebar.
6.The component renders the sidebar content conditionally based on the activeMenu state. If the activeMenu is true, the sidebar is visible, otherwise, it's hidden.
7.The logo image is displayed at the top of the sidebar and serves as a link to the homepage. Clicking on it closes the sidebar on smaller screens.
8.The sidebar displays a list of links and icons for different sections of the application. The links are generated dynamically from the links data array.
9.Each link is wrapped in a NavLink component to provide navigation functionality. The isActive prop of NavLink is used to apply the activeLink or normalLink style based on whether the link is currently active or not.

 */
