import React from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { useSelector } from "react-redux";
import { user } from "../../redux/reducers/userReducer";

const AddressCard = () => {
  const { activeMenu } = useStateContext();
  const currentuser = useSelector(user);

  return (
    <div
      className={`w-full xl:w-[55%] xl:h-[450px] p-4 bg-white shadow rounded-sm ${
        activeMenu ? "lg:w-full md:w-full" : "md:w-full"
      }`}
    >
      <span className="p-2 text-xl text-primary-color font-semibold">
        Additional Information
      </span>
      <div className="mt-10 text-md flex flex-col gap-5 px-3">
        <div className="md:flex gap-3 text-gray-700 font-medium ">
          <span>Street Address :</span>
          <span className="text-primary-color ">{currentuser.address}</span>
        </div>
        <span className="h-[1px] w-full bg-gray-200 lg:w-full"></span>
        <div className="flex gap-3 text-gray-700 font-medium">
          <span>City :</span>
          <span className="text-primary-color">{currentuser.city}</span>
        </div>
        <span className="h-[1px] w-full bg-gray-200 lg:w-full"></span>
        <div className="flex gap-3 text-gray-700 font-medium">
          <span>State :</span>
          <span className="text-primary-color">{currentuser.state}</span>
        </div>
        <span className="h-[1px] w-full bg-gray-200 lg:w-full"></span>
        <div className="flex gap-3 text-gray-700 font-medium">
          <span>ZIP Code :</span>
          <span className="text-primary-color">{currentuser.zipcode}</span>
        </div>
        <span className="h-[1px] w-full bg-gray-200 lg:w-full"></span>
        <div className="flex gap-3 text-gray-700 font-medium">
          <span>Martial Status:</span>
          <span className="text-primary-color">
            {currentuser.maritialstatus}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AddressCard;

/**
 * The AddressCard component is designed to display additional information about the user's address and marital status.
 *  It is a reusable component that can be used to show address details in a formatted card within your React application.

Props:
The AddressCard component does not accept any props directly. 
Instead, it uses the data from the Redux store to fetch the current user's address and marital status.
 */
