import React, { useState, useEffect } from "react";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db, auth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useDispatch } from "react-redux";
import { SetUser, SetUserId } from "../../redux/reducers/userReducer";
import {
  Advertisement,
  Membershipcard,
  Rewardcard,
  Share,
} from "../../components";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeMenu } = useStateContext();
  const [isClicked, setIsClicked] = useState(false);

  // getting the user info once logged in
  useEffect(() => {
    const fetchUser = async () => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // get user info
          const userRef = collection(db, "users/userProfiles/AllUsers");
          const queryRef = query(userRef, where("email", "==", user.email));
          onSnapshot(queryRef, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const userinfo = doc.data();
              const userid = doc.id;
              dispatch(SetUser(userinfo));
              dispatch(SetUserId(userid));
            });
          });
        }
      });
    };
    fetchUser();
  }, []);

  const handleShare = () => {
    setIsClicked(true);
  };

  return (
    // mt-20 md:mt-10 ml-5 mr-5
    <div className="mt-20 md:mt-0">
      <div
        className={`rounded-sm text-gray-700 xl:flex gap-4 my-4 xl:mx-6 mx-3 md:bg-secondary-bg md:p-4 relative ${
          activeMenu ? "" : "lg:flex"
        }`}
      >
        <div
          className={` w-full rounded-sm p-2 h-full bg-white shadow ${
            activeMenu ? "" : ""
          }`}
        >
          <div className="m-1 grid gap-4">
            {/* ---------------------------referral card-------------------------------- */}
            <div
              onClick={handleShare}
              className="bg-button-bg text-white p-4 rounded-sm text-center text-sm md:text-lg font-extrabold 
              flex items-center gap-1 justify-center cursor-pointer
           "
            >
              <span> Refer a friend and get $50 off on any service</span>
            </div>
            {/* -----------------------------Advertisement-------------------------------------------------- */}
            <div className={`hidden lg:block ${activeMenu ? "" : ""}`}>
              <Advertisement />
            </div>
            <div
              className={`grid md:grid-cols-2 xl:grid-cols-3 gap-4 ${
                activeMenu ? "md:grid-cols-1 lg:grid-cols-2" : "lg:grid-cols-1"
              }`}
            >
              {/* -------------------------reward card------------------------------------ */}

              <div className="xl:col-span-1 ">
                <Rewardcard />
              </div>
              <div className="xl:col-span-2 ">
                <div
                  className={`grid gap-4 xl:h-full h-[300px]
                ${
                  activeMenu
                    ? "md:h-[300px] lg:h-full"
                    : "md:h-full lg:h-[300px]"
                }
                `}
                >
                  <div
                    onClick={() => navigate("/booking")}
                    className="bg-[#a8dadc] cursor-pointer rounded-sm text-lg font-bold flex justify-center items-center 
                "
                  >
                    Schedule an appointment
                  </div>
                  <div
                    onClick={() => navigate("/healthhistory")}
                    className="bg-secondary-bg cursor-pointer rounded-sm text-lg font-bold flex justify-center items-center "
                  >
                    Your medical history
                  </div>
                </div>
              </div>
            </div>
            {/* ---------------------------membershipcard------------------------------------------------- */}
             <div>
              <Membershipcard />
            </div> 
          </div>
        </div>
      </div>
      {isClicked && <Share setIsClicked={setIsClicked} />}
    </div>
  );
};

export default Dashboard;

/**
 ** Documentation: Dashboard Component
The Dashboard component represents the main dashboard page of the application, where users can access various features and information related to their account and services. 
The dashboard includes sections for referrals, advertisements, rewards, appointment scheduling, medical history, and membership details. Additionally, there is a "Refer a friend" section that allows users to share a referral link.

**State Variables:
isClicked: A state variable to track whether the "Refer a friend" section is clicked or not. It controls the display of the Share component.

**Helper Functions:
handleShare: A function that sets the isClicked state variable to true when the "Refer a friend" section is clicked. It triggers the display of the Share component.

**Component Structure:

1. The component renders a main dashboard section with multiple sub-sections, including referral cards, advertisements, reward cards, and appointment scheduling.
2. The Rewardcard component is displayed in one of the sections, providing details about the user's rewards or loyalty program.
3. The appointment scheduling section includes buttons to schedule an appointment and view the user's medical history. 
  Clicking these buttons navigates to the corresponding pages (/booking and /healthhistory).
4. The Membershipcard component is displayed in another section, providing details about the user's membership.

**Share Component:
When the user clicks on the "Refer a friend" section, the Share component is displayed. 
The Share component allows users to share a referral link with their friends.

**Usage:
The Dashboard component is the main page of the application, and it provides users with an overview of their account information
 and available services. Users can access various features by clicking on the corresponding buttons and links. 
 The "Refer a friend" section allows users to share a referral link with their friends, potentially earning rewards or discounts.
 */
