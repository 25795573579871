import React, {useState} from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { ProfileCard, AddressCard } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import { useSelector } from "react-redux";
import { user } from "../../redux/reducers/userReducer";

const Profile = () => {
  const { activeMenu } = useStateContext();
  const currentuser = useSelector(user);
  const [toggleState, setToggleState] = useState(1);

const toggleTab = (index) =>{
  setToggleState(index);
}
  return (
    <div className="mt-20 md:mt-10 mx-3 mb-3">
      {/* top section */}

      <div className="flex items-center justify-between p-1 md:m-2">
        <span className=" text-gray-700 capitalize md:text-2xl font-medium text-lg">
          My Profile
        </span>
        <div className="flex text-gray-700 gap-4">
          <NavLink
            to="/editprofile"
            className="bg-[#C0DCDD] p-2 flex items-center gap-2 rounded-sm transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 hover:bg-[#a1ccce] duration-300"
          >
            <span>Edit Profile</span>
            <AiOutlineEdit />
          </NavLink>
        </div>
      </div>

      {/* <div className="flex items-center justify-between p-1 md:m-2">
        <div onClick={()=>toggleTab(1)}>My Profile</div>
        <div onClick={()=>toggleTab(2)}>Family Members</div>
      </div> */}

      <div
        className={`xl:flex mt-4 md:mx-5 gap-6 md:p-6 justify-center md:bg-secondary-bg rounded-sm ${
          activeMenu ? "" : "lg:flex "
        }`}
      >
        {/* profile section */}
        <ProfileCard
          name={currentuser.name}
          email={currentuser.email}
          phone={currentuser.phone}
          gender={currentuser.gender}
          dob={currentuser.dob}
          age={currentuser.age}
          image={currentuser.imageUrl}
        />
        {/* Address section*/}
        <AddressCard />
      </div>
    </div>
  );
};

export default Profile;

/**
 **Documentation: Profile Component

The Profile component represents the user profile page. It displays the user's profile information, including their name, 
email, phone number, gender, date of birth, age, and profile image. 
The component also provides a link to edit the user's profile.

**Dependencies:
react: The core library for building user interfaces in React applications.
react-router-dom: A library that provides routing functionality for React applications.
react-icons/ai: A library that provides access to the Ant Design icon set.
redux: A state management library for React applications.
react-redux: The official React bindings for Redux.

**State Variables:
currentuser: Holds the user's profile information obtained from the Redux store.

**Hooks:
useStateContext: A custom hook from the ContextProvider that returns the active menu state.
useSelector: A hook from react-redux that allows accessing data from the Redux store.

**Component Structure:
The component is a functional component that uses the useStateContext hook to get the active menu state and the 
useSelector hook to access the currentuser data from the Redux store.
The component includes a top section that displays the page title "My Profile" and a link to edit the user's profile.
The main content section displays the user's profile information using the ProfileCard component, 
and the user's address information using the AddressCard component.
The ProfileCard component displays the user's name, email, phone number, gender, date of birth, age, and profile image.
The AddressCard component would display the user's address information,

**Rendered Output:
The component renders a page displaying the user's profile information, 
including their name, email, phone number, gender, date of birth, age, and profile image. 
It also provides a link to edit the user's profile.
 */
