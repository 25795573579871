import React from "react";
import checkin from "../../data/checkin.png";
import { useNavigate } from "react-router-dom";

const Rewardcard = () => {
  const navigate = useNavigate();

  return (
    <div className=" bg-[#edf2f4] rounded-sm grid gap-2 shadow-sm ">
      <div className="grid gap-3 p-2 ">
        <div className="flex justify-center ">
          <img
            src={checkin}
            alt="checkin"
            onClick={() => navigate("/rewards")}
            className="w-32 h-32 object-cover rounded-full cursor-pointer"
          />
        </div>

        <div className="mt-2 mb-2 grid gap-4 w-full">
          <span className="text-xl font-semibold text-center">
            Reward Checker
          </span>
        </div>
      </div>

      <div className="p-4 bg-button-bg text-white grid gap-3">
        <div className="text-xl font-medium">Check in for Rewards!</div>
        <div className="text-sm font-bold uppercase">
          Earn rewards by checking in
        </div>
        <div
          onClick={() => navigate("/rewards")}
          className="border-1  rounded-sm text-sm font-semibold
          text-white text-center py-2 px-4 w-fit cursor-pointer hover:shadow-lg
          hover:bg-secondary-bg hover:text-gray-700 ease-in duration-200 
        "
        >
          Learn more
        </div>
      </div>
    </div>
  );
};

export default Rewardcard;

/** 
 
**OVERVIEW
 The Rewardcard component displays a reward card with information about a reward checker feature.
 Users can click on the card to learn more about earning rewards through check-ins.

 **FUNCTIONALITY:
 navigate: The useNavigate hook is used to get the navigation function to redirect 
 users to the "/rewards" page when they click on the image or the "Learn more" button.


 **/
