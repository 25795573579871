import React from "react";
import { useState } from "react";
import { auth } from "../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { Tooltip } from "@mui/material";

const Forgotpassword = ({ setIsForgot }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email).then(() => {
        setSuccess(
          "Password reset request was sent successfully. Please check your email to reset your password"
        );
        setError("");
      });
    } catch (err) {
      console.log(err.code);
      setError(validateEmail(err.code));
    }
  };
  const validateEmail = (err) => {
    let error = "";
    if (err === "auth/missing-email") {
      error = "Email is required.";
    }
    if (err === "auth/invalid-email") {
      error = "Please enter valid email address";
    }
    if (err === "auth/user-not-found") {
      error = "We cannot find the email.";
    }

    return error;
  };
  return (
    <div className="flex flex-col space-y-4 text-gray-700 justify-center w-full">
      <div className="flex justify-center items-center p-2">
        <img className="h-24" src="../assets/bitcarelogo.webp" alt="Bitcare" />
      </div>
      <div className="flex flex-col gap-4 p-4">
        <div className="grid gap-2 ">
          <span className="text-sm font-medium text-center">
            Enter your email and we'll send you a link to reset your password
          </span>
        </div>
        <form onSubmit={handleSubmit} className="grid gap-6">
          <div className="grid">
            <label
              htmlFor="email"
              className="font-bold text-xs uppercase tracking-wide"
            >
              Email
            </label>
            <Tooltip title={error} open={Boolean(error)} placement="top-end">
              <input
                type="text"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`mt-1 block w-full  rounded-sm  shadow-sm  sm:text-sm
                      ${
                        error
                          ? "border-red-500 focus:ring-0 focus:border-red-500"
                          : "border-gray-400 focus:border-primary-color focus:ring-primary-color focus:ring-0"
                      }
                    `}
              />
            </Tooltip>
            {success && (
              <p className="text-xs font-bold text-green-600 mt-1">{success}</p>
            )}
          </div>
          <button
            type="submit"
            className="bg-primary-color p-3 text-white font-semibold uppercase hover:shadow-xl"
          >
            Reset Password
          </button>
        </form>
        <div className="flex justify-center">
          <span
            onClick={() => setIsForgot(false)}
            className="cursor-pointer text-sm font-semibold"
          >
            Back to login
          </span>
        </div>
      </div>
    </div>
  );
};

export default Forgotpassword;

/**
 * 
**Documentation: Forgotpassword Component
The Forgotpassword component is responsible for rendering the forgot password form, allowing users to 
reset their passwords by providing their registered email address.

**Props:
setIsForgot: A function passed from the parent component to control the state of whether the user is on the forgot password screen or the login screen.

**State:
email: A state variable that holds the user's email address input.
error: A state variable that holds any error message related to the email input.
success: A state variable that holds a success message when the password reset email is successfully sent.

**Functions:
handleSubmit: An asynchronous function that handles the form submission when the user clicks the "Reset Password" button. It attempts to send a password reset email to the provided email address and handles any errors that may occur during the process.
validateEmail: A function that takes an error code as input and returns the corresponding error message for email validation.

**Component Structure:
1.The component renders a form with an input field for the user's email and a "Reset Password" button.
2.When the form is submitted, the handleSubmit function is called to initiate the password reset process.
3.If there is any error with the email input (missing email, invalid email format, or user not found), a tooltip is displayed with the corresponding error message.
4.If the password reset email is sent successfully, a success message is displayed below the email input field.
5.A "Back to login" link is provided to navigate back to the login screen when clicked.

**Usage:
The Forgotpassword component is used within the authentication flow to allow users to reset their passwords if they have forgotten them. 
When a user clicks on the "Forgot Password?" link on the login screen, 
the setIsForgot function is called to switch the view to the Forgotpassword component, 
enabling users to enter their email address and reset their passwords.
 */
